'use client';

import { Button, buttonVariants } from '@/components/ui/button';
import Link from 'next/link';
import { useEffect } from 'react';

export default function ErrorPage({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    console.error(error);
  }, [error]);
  return (
    <div className='flex h-screen w-full flex-col items-center justify-center gap-2'>
      <h1>Error - {error.message}</h1>
      <Link
        prefetch={false}
        className={buttonVariants({ variant: 'link' })}
        href='/dashboard'
      >
        Gå till startsidan
      </Link>
      <Button variant={'outline'} onClick={() => reset()}>
        Försök igen
      </Button>
    </div>
  );
}
